<template>
    <div class="d-flex flex-column w-100">
        <v-app-bar flat color="black" :height="vAppBarHeight" style="z-index: 1010; width: 100vw" :class="smAndDown ? '' : 'px-4'" :style="smAndDown ? 'font-size: 0.65rem' : 'font-size: 1.4rem'">
            <div class="d-flex align-center justify-center" :class="smAndDown ? 'ml-2' : ''">
                <stylized-title style="cursor: pointer" @click="$router.push('/')" :size="smAndDown ? 2 : 2.5" />
            </div>
            <div class="title-font mx-auto" :class="smAndDown ? 'mobile' : ''">Take <div class="animate__animated animate__fadeIn animate__slower">B</div>ack Contro<span class="animate__animated animate__fadeIn animate__delay-1s animate__slower">l</span> of Yo<span class="animate__animated animate__fadeIn animate__delay-3s animate__slower">u</span>r P<span class="animate__animated animate__fadeIn animate__delay-5s animate__slower">r</span>ivacy</div>
            <v-btn size="x-small" class="" @click="emit('update:theme')" :icon="theme === 'dark' ? 'dark_mode' : 'light_mode'" variant="plain" />
            <div v-if="!smAndDown" class="mr-16 font-weight-thin text-no-wrap">
                <nav-menu class="px-2" :signup="signup" ref="nav-account" type="account" />
                <v-btn class="px-2" :disabled="/home|root/.test($route.name)" to="/home" variant="text" :size="smAndDown ? 'small' : 'large'" text="Home" />
                <nav-menu class="px-2" ref="nav-info" type="info" />
                <nav-menu class="px-2" ref="nav-support" type="support" />
                <nav-menu class="px-2" ref="nav-about" type="about" />
            </div>
            <nav-menu :signup="signup" ref="nav-mobile" icon="menu" v-else type="mobile" />
        </v-app-bar>
        <div id="image-banner" :style="`top: ${vAppBarHeight}px; height: ${imageBannerHeight}px`" :class="smAndDown ? 'mobile' : ''" v-if="!/\/account|\/worker.*/.test($route.path)">
            <v-img id="zoom-image" ref="imageRef" @wheel.prevent="imageBannerWheelHandler" :src="`/ethan-hansen-4EmfzlONK8A-unsplash${blur ? '-blurred' : ''}${smAndDown ? '-mobile' : ''}.webp`" cover />
        </div>
    </div>
</template>
<style scoped>
.title-font {
    font-family: 'Anton', sans-serif;
    font-size: 1.5rem;
    transform: scaleY(1.5);
}
.title-font.mobile {
    font-size: 1rem;
}
.title-font .animate__animated {
    display: inline;
}
.subtitle {
    position: absolute;
    bottom: -10px;
}

.sticker-text {
    font-size: 24px;
    font-weight: bold;
    color: white;
    /* Text color */
    -webkit-text-stroke: 2px black;
    /* Width and color of the text stroke */
    display: inline-block;
    /* Ensures that the background color only covers the text content */
    background-color: #ffcc00;
    /* Background color of the sticker */
    padding: 8px 16px;
    /* Padding for spacing around the text */
    border-radius: 8px;
    /* Optional: Adds rounded corners to the sticker */
}

#image-banner {
    position: relative;
    display: inline-block;
    width: 100%;
    left: 0;
    z-index: 1009;
    overflow: hidden;
}

#imgage-banner img {
    display: block;
    width: 100%;
}

#zoom-image {
    width: 100%;
    height: auto;
    transition: transform 0.2s ease;
    cursor: grab;
}
</style>
<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import { until } from 'async'
import { useRoute } from 'vue-router'
import 'animate.css'

import StylizedTitle from '@/components/StylizedTitle.vue'
import NavMenu from '@/components/nav/NavMenu.vue'

const route = useRoute()
defineProps({
    modelValue: Object,
    signup: Boolean,
    theme: String,
    imageBannerHeight: Number
})
const emit = defineEmits(['update:theme', 'update:modelValue'])

const vAppBarHeight = 75
const { smAndDown } = useDisplay()
const blur = ref(true)
const imageRef = ref()
const imagePosition = ref(60)
const direction = ref('decreasing')
const scale = ref(1)
const scaleStep = 0.1
const maxScale = 3
const minScale = 1
let animationFrameId, interval

function imageBannerWheelHandler(e) {
    if (e.deltaY < 0 && scale.value < maxScale) {
        scale.value += scaleStep
    } else if (e.deltaY > 0 && scale.value > minScale) {
        scale.value -= scaleStep
    }

    imageRef.value.$el.style.transform = `scale(${scale.value})`
}
const updateImagePosition = async (el) => {
    imagePosition.value = direction.value === 'increasing' ? Math.min(imagePosition.value + (smAndDown.value ? 0.3 : 0.1), 100) : Math.max(imagePosition.value - (smAndDown.value ? 0.2 : 0.05), 0)

    el.style.objectPosition = `0 ${imagePosition.value}%`
    if (imagePosition.value == 100 || imagePosition.value == 0) {
        direction.value = direction.value === 'increasing' ? 'decreasing' : 'increasing'
    }

    setTimeout(() => {
        animationFrameId = requestAnimationFrame(() => updateImagePosition(el))
    }, 1000 / 60)
}
async function asyncInit() {
    let el = imageRef.value?.$el?.querySelector('.v-img__img--cover')

    await until(
        async () => {
            if (el) {
                return el
            }
            el = imageRef.value?.$el?.querySelector('.v-img__img--cover')
        },
        async () => await new Promise(resolve => setTimeout(resolve))
    )
    animationFrameId = requestAnimationFrame(() => updateImagePosition(el))
}
onMounted(() => {
    asyncInit()
    watch(() => route.path, () => {
        cancelAnimationFrame(animationFrameId)
        asyncInit()
    })
    watch(() => direction.value, direction => {
        if (direction === 'increasing') {
            setTimeout(() => {
                blur.value = !blur.value  
            }, 1500)
        }
    })
    setTimeout(() => {
        document.querySelector('.title-font')?.classList.add('animate__animated', 'animate__fadeOut', 'animate__slower')
    }, 10000)
})
onBeforeUnmount(() => {
    cancelAnimationFrame(animationFrameId)
    clearInterval(interval)
})
</script>
