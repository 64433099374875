<template>
    <div :style="smAndDown ? `font-size: ${size || '1.5'}rem` : `font-size: ${size || '2'}rem`">
        <span v-if="!brief" class="font-weight-bold" :class="light ? color ? `text-${color}` : '' : color && `text-${color}` ||'text-red-darken-4'">
            <span :style="`filter: blur(${props.print ? 0 : 0}px)`">B</span>
            <span :style="`filter: blur(${props.print ? 3 : 2}px)`">L</span>
            <span :style="`filter: blur(${props.print ? 9 : 3}px)`">U</span>
            <span :style="`filter: blur(${props.print ? 27 : 4}px)`">R</span>
        </span>
    </div>
</template>
<script setup>
import { useDisplay } from 'vuetify/lib/framework.mjs'

const { smAndDown } = useDisplay()

const props = defineProps({
    brief: Boolean,
    light: Boolean,
    size: Number,
    color: String,
    print: Boolean
})
</script>