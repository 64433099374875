/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'
import vuetify from './vuetify'
import pinia from '../store'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import api from './api'
import clipboard from './clipboard.plugin'
import keycloakPlugin from './keycloak.plugin'
import ghostPlugin from './ghost.plugin'
import router from '../router'

const {
    VITE_APP_KEYCLOAK_URL,
    VITE_APP_KEYCLOAK_REALM,
    VITE_APP_KEYCLOAK_CLIENT_ID,
    VITE_APP_RECAPTCHA_V2_KEY,
    VITE_APP_RECAPTCHA_V3_KEY
} = import.meta.env

pinia.use(piniaPluginPersistedstate)

export function registerPlugins(app) {
    app
        .use(VueRecaptchaPlugin, {
            v2SiteKey: VITE_APP_RECAPTCHA_V2_KEY,
            v3SiteKey: VITE_APP_RECAPTCHA_V3_KEY
        })
        .use(keycloakPlugin, {
            keycloakConfig: {
                url: VITE_APP_KEYCLOAK_URL,
                realm: VITE_APP_KEYCLOAK_REALM,
                clientId: VITE_APP_KEYCLOAK_CLIENT_ID,
            },
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
            responseMode: 'query'
        })
        .use(pinia)
        .use(router)
        .use(ghostPlugin)
        .use(vuetify)
        .use(api)
    app.provide('clipboard', clipboard)
}
