<template>
    <v-menu :open-on-hover="type !== 'mobile'">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :disabled="disabled" variant="text" :size="type === 'mobile' ? 'small' : 'large'" :text="type !== 'mobile' ? type : undefined" :icon="icon" :class="class" />
        </template>
        <v-list :items="items" :density="smAndDown ? 'compact' : 'default'">
            <template v-slot:divider>
                <v-divider class="my-2" color="white" />
            </template>
            <template v-slot:subheader="{ props }">
                <v-list-subheader :class="/main|account/i.test(props.title) ? 'd-flex align-center justify-center text-white font-weight-bold' : 'text-caption ml-2'">{{ props.title }}</v-list-subheader>
            </template>
            <template v-slot:item="{ props }">
                <v-list-item exact class="py-0" :class="props.type === 'about' ? 'text-end' : ''" :to="props.value?.to" :href="props.value?.href" @click="props.value?.action">
                    <span class="text-white text-uppercase font-weight-thin mr-2">{{ props.title }}</span>
                    <span class="text-white font-weight-thin">{{ props.subtitle }}</span>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>
<style scoped>
:deep() .v-list {
    background: #b71c1c !important;
}

.v-overlay-container {
    color: red;
}

.v-list-item--density-compact {
    min-height: unset !important;
}
</style>
<script setup>
import { computed, onMounted, getCurrentInstance, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAppStore } from '@/store/app'
import { useDisplay } from 'vuetify/lib/framework.mjs'

const props = defineProps({
    type: String,
    icon: String,
    class: String,
    signup: Boolean,
    disabled: Boolean
})
const { smAndDown } = useDisplay()
const store = useAppStore()
const { $keycloak } = getCurrentInstance().appContext.config.globalProperties
const login = () => $keycloak.value.login({ redirectUri: `${window.location.origin}/account` })
const logout = () => {
    $keycloak.value.logout({ redirectUri: `${window.location.origin}/home` })
    store.$reset()
}
const isAuthenticated = ref()
const route = useRoute()
const homeArr = computed(() =>
    route.name !== 'home'
        ? [
            {
                title: 'Home',
                value: {
                    to: '/home',
                },
            },
        ]
        : []
)
const supportArr = [
    {
        title: 'FAQ',
        value: {
            to: '/faq',
        },
    },
    {
        title: 'help',
        value: {
            to: '/help',
        },
    },
]
const aboutArr = [
    {
        title: 'What BLUR Is',
        value: {
            to: '/what',
        },
    },
    {
        title: 'testimonials',
        value: {
            to: '/testimonials',
        },
    },
    {
        title: 'contact',
        value: {
            to: '/contact',
        },
    },
]
const infoArr = [
    {
        title: 'Pricing',
        value: {
            to: '/pricing',
        },
    },
    {
        title: 'How It Works',
        value: {
            to: '/how',
        },
    },
    {
        title: 'Professional',
        value: {
            to: '/pro',
        },
    },
]
const accountArr = ref([
    {
        title: 'Dashboard',
        value: {
            to: '/account',
        },
    },
    { type: 'divider' },
    {
        title: computed(() => (isAuthenticated.value ? 'sign out' : 'sign in')),
        value: {
            action: computed(() => (isAuthenticated.value ? logout : login)),
        },
    }
])
const items = computed(() => {
    if (props.type === 'home') {
        return homeArr.value
    } else if (props.type === 'account') {
        return accountArr.value
    } else if (props.type === 'info') {
        return infoArr
    } else if (props.type === 'about') {
        return aboutArr
    } else if (props.type === 'support') {
        return supportArr
    } else if (props.type === 'mobile') {
        return [{ type: 'subheader', title: 'ACCOUNT' }, ...accountArr.value, { type: 'subheader', title: 'MAIN' }, ...homeArr.value, { type: 'subheader', title: 'info' }, ...infoArr, { type: 'subheader', title: 'about' }, ...aboutArr, { type: 'subheader', title: 'support' }, ...supportArr,]
    }
})
async function asyncInit() {
    await $keycloak.value.isLoaded
    if ($keycloak.value.isAuthenticated) {
        isAuthenticated.value = $keycloak.value.isAuthenticated
    }
}
onMounted(() => {
    asyncInit()
    watch(() => props.signup, signup => {
        if (signup) {
            $keycloak.value.login({ redirectUri: `${window.location.origin}/account`, action: 'register' })
        }
    })
})
</script>
