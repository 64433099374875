// Composables
import { createRouter, createWebHistory } from 'vue-router'

export default (app) => {
    const setupRoutes = $keycloak => {
        const { isAuthenticated, isLoading } = $keycloak
        const accountMenu = {
            name: 'account',
            items: [
                { title: 'dashboard', to: '/account' }
            ]
        }

        return [
            {
                path: '/batteries',
                name: 'Batteries',
                meta: {
                    title: 'Batteries',
                    description: '',
                },
                component: () => import('@/views/Batteries.vue')
            },
            {
                path: '/advoid',
                name: 'ADvoid',
                meta: {
                    title: 'ADvoid Browser Extension',
                    description: 'ADvoid is a powerful browser extension that blocks ads on Amazon Prime Video.',
                    favicon: '/advoid/favicon.ico'
                },
                component: () => import('@/views/AdvoidPage.vue')
            }, {
                path: '/deja',
                name: 'dEjA',
                meta: {
                    title: 'dEjA Browser Extension',
                    description: 'DeJa is a powerful browser extension that gives you full control over your music across all platforms. Effortlessly play, pause, skip, and manage your tunes from any device, anywhere.',
                    favicon: '/deja/favicon.ico'
                },
                component: () => import('@/views/DejaPage.vue'),
                children: [{
                    path: '',
                    name: 'app',
                    meta: {
                        title: 'DeJa Web App: Control Your Music Anywhere, Anytime',
                        description: 'DeJa is a powerful browser extension that gives you full control over your music across all platforms. Effortlessly play, pause, skip, and manage your tunes from any device, anywhere.',
                        favicon: '/deja/favicon.ico'
                    },
                    component: () => import('@/components/pages/deja/AppPage.vue')
                }, {
                    path: 'buy',
                    name: 'buy',
                    meta: {
                        title: 'Get DeJa: Unlock Full Music Control with a Subscription',
                        description: 'DeJa is a powerful browser extension that gives you full control over your music across all platforms. Effortlessly play, pause, skip, and manage your tunes from any device, anywhere.',
                        favicon: '/deja/favicon.ico'
                    },
                    component: () => import('@/components/pages/deja/BuyPage.vue')
                }]
            }, {
                path: '/faq',
                name: 'faq',
                meta: {
                    title: 'Frequently Asked Questions',
                    menu: 'home'
                },
                component: () => import('@/views/FAQ.vue'),
                beforeEnter(to, from, next) {
                    // Load or include your specific CSS file here
                    // For example, you can add a link tag to the head of the document
                    const link = document.createElement('link')
                    link.rel = 'stylesheet'
                    link.href = 'https://blog.june07.com/public/cards.min.css?v=4399011e9b'
                    document.head.appendChild(link)

                    document.querySelector('')

                    next()
                },
            }, {
                path: '/help',
                name: 'help',
                meta: {
                    summary: 'Get Help',
                    title: 'Let Us Help',
                    subtitle: '',
                    menu: 'support'
                },
                component: () => import('@/views/Help.vue')
            }, {
                path: '/',
                name: 'base',
                component: () => import('@/views/BasePage.vue'),
                children: [{
                    path: '/',
                    name: 'root',
                    meta: {
                        summary: 'BLUR',
                        title: 'Blur Your Home Across the Internet — SIMPLY',
                        subtitle: 'Keep your home private by blurring it on Google, Apple, and Microsoft Maps, as well as real estate platforms like Zillow, Realtor.com, and more. Blur provides a comprehensive privacy solution for your property.',
                        menu: 'home'
                    },
                    component: () => import('@/components/pages/HomePage.vue')
                }, {
                    path: '/home',
                    name: 'home',
                    meta: {
                        summary: 'Home',
                        title: 'Blur Your Home Across the Internet — SIMPLY',
                        subtitle: 'Keep your home private by blurring it on Google, Apple, and Microsoft Maps, as well as real estate platforms like Zillow, Realtor.com, and more. Blur provides a comprehensive privacy solution for your property.',
                        menu: 'home'
                    },
                    component: () => import('@/components/pages/HomePage.vue')
                }, {
                    path: '/how',
                    name: 'how it works',
                    meta: {
                        summary: 'How',
                        title: 'How does BLUR work?',
                        subtitle: 'BLUR is a cutting-edge solution that leverages modern technologies to automate the otherwise tedious, time-consuming, and costly process of blurring your home and personal details on platforms such as Google Maps, Apple Maps, Microsoft Maps, and others. Our service is designed to provide an efficient and effective way to protect your privacy without the hassle.',
                        menu: 'inquire'
                    },
                    component: () => import('@/components/pages/HowPage.vue')
                }, {
                    path: '/pricing',
                    name: 'pricing',
                    meta: {
                        summary: 'Pricing',
                        title: 'Your Digital Privacy Starts Here',
                        subtitle: 'Protect Your Digital Information From Adversaries with BLUR',
                        title2: 'Powerful Data Archiving for Professionals',
                        subtitle2: 'Easily capture and store historical Google Maps Street View imagery with high-resolution panoramic views. Access and download archival data with convenience and precision.',
                        menu: 'inquire'
                    },
                    component: () => import('@/components/pages/PricingPage.vue'),
                }, {
                    path: '/what',
                    name: 'what',
                    meta: {
                        summary: 'What',
                        title: 'What BLUR Is',
                        subtitle: `BLUR is an automated privacy solution that blurs your information on Google Maps, Apple Maps, Microsoft Maps, Zillow, and other platforms. Additionally, it provides comprehensive solutions for legitimate professionals seeking high-resolution, organized visual data.`,
                        menu: 'about'
                    },
                    component: () => import('@/components/pages/WhatPage.vue'),
                    beforeEnter(to, from, next) {
                        const link = document.createElement('link')
                        link.rel = 'stylesheet'
                        link.href = 'https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap'
                        document.head.appendChild(link)

                        next()
                    },
                }, {
                    path: '/testimonials',
                    name: 'testimonials',
                    meta: {
                        summary: 'Reviews',
                        title: 'What people are saying about BLUR',
                        subtitle: '"The commitment to quality sets BLUR apart."',
                        menu: 'about'
                    },
                    component: () => import('@/components/pages/TestimonialsPage.vue')
                }, {
                    path: '/contact',
                    name: 'contact',
                    meta: {
                        summary: 'Contact',
                        title: 'Contact',
                        subtitle: `Looking forward to hearing from you!`,
                        menu: 'about'
                    },
                    component: () => import('@/components/pages/ContactPage.vue')
                }, {
                    path: '/pro',
                    name: 'pro',
                    meta: {
                        summary: 'Pro',
                        title: 'Comprehensive Archival Solutions for Google Maps Street View Imagery',
                        subtitle: 'Easily archive historical Google Maps Street View imagery, including multiple vantage points and dates, to support your projects. Ideal for real estate agents, urban planners, architects, and researchers seeking high-resolution, organized visual data.',
                    },
                    component: () => import('@/components/pages/ProPage.vue')
                }, {
                    path: '/account',
                    name: 'account',
                    meta: {
                        summary: 'My Account',
                        title: 'My Account',
                        subtitle: '',
                        menu: accountMenu
                    },
                    component: () => import('@/components/pages/AccountPage.vue'),
                    beforeEnter: (async (to, _from, next) => {
                        await $keycloak.value.isLoaded
                        if ($keycloak.value.authenticated) {
                            next()
                        } else {
                            await $keycloak.value.login({
                                redirectUri: `${window.location.origin}${to.fullPath}`
                            })
                            next(false)
                        }
                    }),
                    children: [{
                        path: '',
                        name: 'dashboard',
                        meta: {
                            summary: 'Dashboard',
                            title: 'Dashboard',
                            subtitle: 'Dashboard',
                            menu: accountMenu
                        },
                        component: () => import('@/components/AccountDashboard.vue')
                    }]
                }, {
                    path: '/worker-signup',
                    name: 'worker-signup',
                    meta: {
                        summary: 'Worker Signup',
                        title: 'Worker Signup',
                        subtitle: 'Sign up as a BLUR worker to perform HITs.',
                    },
                    component: () => import('@/components/WorkerSignup.vue')
                }, {
                    path: '/worker',
                    name: 'worker',
                    meta: {
                        summary: 'Worker',
                        title: 'Worker Dashboard',
                        subtitle: 'This dashboard is for authorized users only. Unauthorized access and/or usage is strictly prohibited by law.',
                    },
                    component: () => import('@/components/WorkerDashboard.vue'),
                    beforeEnter: (async (to, _from, next) => {
                        await $keycloak.value.isLoaded

                        // debugger
                        if ($keycloak.value.authenticated) {
                            const isAuthorized = $keycloak.value.hasResourceRole('client-worker', 'blur')

                            next(isAuthorized ? true : { path: '/worker', query: { response: 'forbidden' } })
                        } else if (to.query.forceSignin) {
                            await $keycloak.value.login({
                                redirectUri: `${window.location.origin}${to.fullPath}`
                            })
                            next(false)
                        } else {
                            next({ path: '/worker-signup' })
                        }
                    }),
                    children: [{
                        path: '',
                        name: 'worker-dashboard',
                        meta: {
                            summary: 'Worker',
                            title: 'Worker Dashboard',
                            subtitle: 'This dashboard is for authorized users only. Unauthorized access and/or usage is strictly prohibited by law.',
                        },
                        component: () => import('@/components/WorkerDashboard.vue')
                    }]
                }]
            }, {
                path: '/:catchAll(.*)',
                name: 'catchall',
                component: () => import('@/views/BasePage.vue')
            },
        ]
    }
    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes: setupRoutes(app.config.globalProperties.$keycloak),
        scrollBehavior(to, from, savedPosition) {
            // If the navigation is triggered to the same route (same path)
            if (to.path === from.path) {
                return { x: 0, y: 0 }  // Scroll to the top
            }

            // If there is a savedPosition (back/forward navigation), restore the scroll position
            if (savedPosition) {
                return savedPosition
            }

            // For normal navigation between different routes, don't scroll to the top
            return { top: 0, left: 0 }
        }
    })

    router.beforeEach((to, from, next) => {
        if (to.meta.title) {
            document.title = to.meta.title
        }
        if (to.meta.description) {
            let metaDescription = document.querySelector('meta[name="description"]')

            if (metaDescription) {
                metaDescription.setAttribute('content', to.meta.summary)
            } else {
                metaDescription = document.createElement('meta')
                metaDescription.name = "description"
                metaDescription.content = to.meta.summary
                document.head.appendChild(metaDescription)
            }
        }
        if (to.meta.favicon) {
            let faviconLinks = Array.from(document.querySelectorAll('link[rel="icon"]'))
            if (faviconLinks.length) {

                faviconLinks.forEach(linkEl => linkEl.href = to.meta.favicon)
            } else {
                faviconLink = document.createElement('link')
                faviconLink.rel = 'icon'
                faviconLink.href = to.meta.favicon
                document.head.appendChild(faviconLink)
            }
        }
        if (/^\/(deja|advoid)/.test(to.path)) {
            const site = to.path.match(/^\/(deja|advoid)/)[1]

            document.querySelector('link[rel="manifest"]').href = `/${site}/site.webmanifest`
        }
        next()
    })

    app.use(router)
}
