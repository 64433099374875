import { ref } from 'vue'
import { io } from 'socket.io-client'

const { VITE_APP_SERVER } = import.meta.env
const state = ref({
    connected: false,
})
const sio = ref()

function ioInit(options) {
    const { userId, deviceId } = options

    sio.value = io(VITE_APP_SERVER + '/batteries', {
        transports: ['websocket'],
        query: Object.fromEntries(Object.entries({
            userId,
            deviceId
        }).filter(([_key, value]) => value)),
        forceNew: true,
        autoConnect: true,
    })

    sio.value.on('connect', () => {
        console.info('connected to batteries namespace')
        state.value.connected = true
    }).on('disconnect', reason => {
        console.info('disconnected from batteries namespace: ', reason)
    })

    return sio.value
}
export { sio, state, ioInit }
